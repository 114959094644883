<!--
 * @Date: 2023-11-28 16:39:59
 * @LastEditors: kevinzgai zhanggai_ok@126.com
 * @LastEditTime: 2023-12-04 17:44:00
 * @FilePath: \zyt-zlb-mobile-develop\src\views\cinemaAssistant\competitorCinema\session.vue
-->
<template>
  <div>
    <van-tabs color="#1989fa" :active="activeTab" @change="tabChange">
      <van-tab title="昨天" name="yesterday"> </van-tab>
      <van-tab title="近7天" name="before7days"> </van-tab>
      <van-tab title="近30天" name="before30days"> </van-tab>
    </van-tabs>
    <div>
      <ve-table
        :scroll-width="0"
        :table-data="tableData"
        :max-height="maxHeight"
        :fixed-header="true"
        :show-header="true"
        :highlight-current-row="true"
        :columns="columns"
        :border-y="true"
      ></ve-table>
    </div>
  </div>
</template>

<script>
import { Notify } from 'vant'
import { zjApi } from '@/api/index'
export default {
  data() {
    return {
      activeTab: 'yesterday',
      ourCinemaId: null,
      // 表格最大高度
      maxHeight: 900,
      tableData: [],
      columns: [
        // { field: "name", key: "a", title: "Name", align: "center" },
        {
          title: '竞对影院名称',
          field: 'cinemaName',
          key: 'cinemaName',
          width: 120,
          fixed: 'left',
        },
        {
          title: '影院位置',
          field: 'cinemaLocation',
          key: 'cinemaLocation',
          width: 120,
        },
        {
          title: '距离',
          field: 'distance',
          key: 'distance',
          width: 90,
          renderBodyCell: ({ row }) => {
            return row.distance + 'km'
          },
        },
        {
          title: '影厅数',
          field: 'hallNum',
          key: 'hallNum',
          width: 90,
          renderBodyCell: ({ row }) => {
            return row.hallNum
          },
        },
        {
          title: '座位数',
          field: 'seatNum',
          key: 'seatNum',
          width: 90,
          renderBodyCell: ({ row }) => {
            return row.seatNum
          },
        },
        {
          title: '每日平均场次',
          field: 'averageSession',
          key: 'averageSession',
          width: 120,
          renderBodyCell: ({ row }) => {
            return row.averageSession
          },
        },
        {
          title: 'Top3 场次的影片',
          field: 'top3films',
          key: 'top3films',
          width: 200,
          renderBodyCell: ({ row }) => {
            return (
              <div class="films">
                {row.top3films.map((item, index) => {
                  return (
                    <div class="ellipsis">
                      <span>{item.name}</span>
                    </div>
                  )
                })}
              </div>
            )
          },
        },
        //操作
        {
          title: '详情',
          field: 'detail',
          key: 'detail',
          width: 60,
          fixed: 'right',
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const text = row[column.field]
            // console.log({ row, column, rowIndex });
            if (rowIndex === 0) {
              return <span>-</span>
            } else {
              return (
                <span
                  style="color: #1989fa;cursor: pointer;"
                  onClick={() => {
                    this.$router.push({
                      path: '/cinemaAssistant/competitorCinema/session/detail',
                      query: {
                        id: row.id,
                        ourCinema: JSON.stringify(this.ourCinema),
                      },
                    })
                  }}
                >
                  详情
                </span>
              )
            }
          },
        },
      ],
    }
  },
  mounted() {
    // 动态计算表格最大高度
    this.maxHeight = window.innerHeight - 10
    // 获取数据
    this.getData()
    // 开场提示表格可向右滚动
    Notify({
      type: 'primary',
      message: '提示:表格可向右滚动->',
      duration: 5000,
    })
  },
  methods: {
    // tab切换
    tabChange(e) {
      console.log(e)
      this.activeTab = e
      this.getData()
    },
    // 获取数据
    getData() {
      this.tableData = []
      //UserCode=1&ourCinemaId=33010201&datetype=before7days
      const params = {
        UserCode: this.$store.state.userInfo.mobile,
        ourCinemaId: this.$route.query.cinemaId,
        datetype: this.activeTab,
      }
      zjApi.getAnalysisSession(params).then((res) => {
        console.log({ res })
        const { code, result } =  res
        this.tableData = result
        if (result.length > 0) {
          this.ourCinema = result[0]
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
/** 一行文本**/
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.films {
  > div {
    /**第一名*/
    &:first-child {
      color: #fb8b05;
    }
    /** 第二名*/
    &:nth-child(2) {
      color: #71361d;
    }
    /** 第三名*/
    &:nth-child(3) {
      color: #1e131d;
    }
  }
}
</style>
